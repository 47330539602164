import { carrierLoadedUrl } from "./api";
import { CARRIER_FAILED, CARRIER_LOADED, CARRIER_LOADING } from "./types";
import Axios from "axios";
import { tokenConfig } from "../actions";

export const loadCarriers = () => (dispatch, getState) => {
  dispatch({ type: CARRIER_LOADING });
  Axios.get(carrierLoadedUrl, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: CARRIER_LOADED, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: CARRIER_FAILED, payload: err.response });
    });
};
