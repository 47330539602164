import {
  loadUserUrl,
  loginUrl,
  registerUserUrl,
  verifyUserUrl,
  forgotUserUrl,
  resetUserUrl,
  updateUserPassUrl,
  updateUserUrl,
  generateAuthKeyUrl,
} from "./api";
import {
  AUTH_FAILED,
  AUTH_LOADED,
  AUTH_LOADING,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_REGISTER,
  AUTH_VERIFY,
  AUTH_FORGOT,
  AUTHH_RESET,
  AUTH_PASS_UPDATE,
  AUTH_UPDATE,
  AUTH_KEY_GENERATE,
} from "./types";
import Axios from "axios";

export const tokenConfig = () => {
  const token = localStorage.getItem("OnwlClientToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: AUTH_LOADING });
  Axios.get(loadUserUrl, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: AUTH_LOADED, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: AUTH_FAILED, payload: err.response });
    });
};

export const loadKey = (func, errFunc) => (dispatch, getState) => {
  dispatch({ type: AUTH_LOADING });
  Axios.get(generateAuthKeyUrl, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: AUTH_KEY_GENERATE, payload: res.data });
      func();
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: AUTH_FAILED, payload: err.response });
      errFunc();
    });
};

export const loginUser = (body, func, funcErr) => (dispatch, getState) => {
  dispatch({ type: AUTH_LOADING });
  Axios.post(loginUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: AUTH_LOGIN, payload: res.data });
    })
    .catch((err) => {
      funcErr();
      dispatch({ type: AUTH_FAILED, payload: err.response });
    });
};
export const forgotUser = (body, func, funcErr) => (dispatch, getState) => {
  dispatch({ type: AUTH_LOADING });
  Axios.post(forgotUserUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: AUTH_FORGOT, payload: res.data });
    })
    .catch((err) => {
      funcErr();
      dispatch({ type: AUTH_FAILED, payload: err.response });
    });
};
export const resetUser = (body, func, funcErr) => (dispatch, getState) => {
  dispatch({ type: AUTH_LOADING });
  Axios.post(resetUserUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: AUTHH_RESET, payload: res.data });
    })
    .catch((err) => {
      funcErr();
      dispatch({ type: AUTH_FAILED, payload: err.response });
    });
};
export const updateUser = (body, func, funcErr) => (dispatch, getState) => {
  dispatch({ type: AUTH_LOADING });
  Axios.put(updateUserUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: AUTH_UPDATE, payload: res.data });
    })
    .catch((err) => {
      funcErr();
      dispatch({ type: AUTH_FAILED, payload: err.response });
    });
};
export const updateUserPass = (body, func, funcErr) => (dispatch, getState) => {
  dispatch({ type: AUTH_LOADING });
  Axios.put(updateUserPassUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: AUTH_PASS_UPDATE, payload: res.data });
    })
    .catch((err) => {
      funcErr();
      dispatch({ type: AUTH_FAILED, payload: err.response });
    });
};

export const verifyUser = (body, func, funcErr) => (dispatch, getState) => {
  dispatch({ type: AUTH_LOADING });
  Axios.post(verifyUserUrl, body, tokenConfig(getState))
    .then((res) => {
      func(res.data);
      dispatch({ type: AUTH_VERIFY, payload: res.data });
    })
    .catch((err) => {
      funcErr(err.response);
      dispatch({ type: AUTH_FAILED, payload: err.response });
    });
};
export const registerUser = (body, func, funcErr) => (dispatch, getState) => {
  dispatch({ type: AUTH_LOADING });
  Axios.post(registerUserUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: AUTH_REGISTER, payload: res.data });
    })
    .catch((err) => {
      funcErr(err.response);
      dispatch({ type: AUTH_FAILED, payload: err.response });
      console.log(err);
    });
};

export const logout = () => (dispatch) => {
  dispatch({ type: AUTH_LOGOUT });
};
