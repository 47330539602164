import { lazy } from "react";

const routes = [
  {
    path: "welcome",
    component: lazy(() => import("../Dashboard/WelcomePage")),
    exact: true,
  },
  {
    path: "profile",
    component: lazy(() => import("../Profile")),
    exact: true,
  },
  {
    path: "account",
    component: lazy(() => import("../AccountSettings")),
    exact: true,
  },
  {
    path: "developer",
    component: lazy(() => import("../DeveloperKeys")),
    exact: true,
  },
  {
    path: "history",
    component: lazy(() => import("../OrderHistory")),
    exact: true,
  },
  {
    path: "wallet",
    component: lazy(() => import("../WalletHistory")),
    exact: true,
  },
  {
    path: "businesses",
    component: lazy(() => import("../Businesses")),
    exact: true,
  },
];

export default routes;
