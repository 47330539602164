import { WALLET_FAILED, WALLET_INTENT, WALLET_LOADING, WALLET_VERIFY, WALLET_HISTORY, WALLET_SELECT } from "./types";

const initialState = {
  loading: false,
  single: null,
  history: [],
  intent: null,
};

const wallet = (state = initialState, action) => {
  switch (action.type) {
    case WALLET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case WALLET_INTENT:
      return {
        ...state,
        loading: false,
        intent: action.payload.model,
      };
    case WALLET_HISTORY:
      return {
        ...state,
        loading: false,
        history: action.payload.model,
      };
    case WALLET_SELECT:
      return {
        ...state,
        single: action.payload,
      };
    case WALLET_FAILED:
    case WALLET_VERIFY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default wallet;
