import { addBusinessUrl, loadBusinessUrl, updateBusinessUrl } from "./apis";
import {
  BUSINESS_ADDED,
  BUSINESS_FAILED,
  BUSINESS_LOADED,
  BUSINESS_LOADING,
  BUSINESS_SET,
  BUSINESS_UPDATE,
  BUSINESS_PAYLOAD,
} from "./types";
import Axios from "axios";
import { tokenConfig } from "../actions";

export const loadBusiness = () => (dispatch, getState) => {
  dispatch({ type: BUSINESS_LOADING });
  Axios.get(loadBusinessUrl, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: BUSINESS_LOADED, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: BUSINESS_FAILED, payload: err.response });
    });
};

export const addBusiness = (body, func, funcErr) => (dispatch, getState) => {
  Axios.post(addBusinessUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: BUSINESS_ADDED, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      funcErr(err.response);
      dispatch({ type: BUSINESS_FAILED, payload: err.response });
    });
};
export const updateBusiness = (body, func, funcErr) => (dispatch, getState) => {
  Axios.put(updateBusinessUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch(loadBusiness());
      dispatch({ type: BUSINESS_UPDATE, payload: res.data });
    })
    .catch((err) => {
      funcErr();
      dispatch({ type: BUSINESS_FAILED, payload: err.response });
    });
};

export const setBusiness = (body, func) => (dispatch) => {
  dispatch({ type: BUSINESS_SET, payload: body });
  func();
};

export const setBusinessPayload = (payload) => (dispatch) => {
  dispatch({ type: BUSINESS_PAYLOAD, payload });
};
