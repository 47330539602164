export const ORDER_STEP = "ORDER_STEP";
export const ORDER_DATA = "ORDER_DATA";
export const ORDER_INIT = "ORDER_INIT";
export const ORDER_LOADED = "ORDER_LOADED";
export const ORDER_SELECT = "ORDER_SELECT";
export const ORDER_FAILED = "ORDER_FAILED";
export const ORDER_LOADING = "ORDER_LOADING";
export const ORDER_DATA_RESET = "ORDER_DATA_RESET";
export const ORDER_TRANS_INIT = "ORDER_TRANS_INIT";
export const ORDER_CLIENT = "ORDER_CLIENT";
export const ORDER_VERIFY = "ORDER_VERIFY";
