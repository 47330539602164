import React, { useState, useEffect } from "react";
import Logo from "../../images/onwl.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "../../pages/auth/AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as Actions from "../../redux/actions";
import { useHistory } from "react-router-dom";
const ForgotPassword = () => {
  const loading = useSelector((state) => state.auth.loading);

  const dispatch = useDispatch();
  const history = useHistory();
  const [payload, setPayload] = useState({ email: "" });
  const [errorVal, setError] = useState("");

  const onFormSubmit = (formData) => {
    dispatch(
      Actions.forgotUser(
        formData,
        () => {
          history.push("/response", {
            data: {
              title: "Password Reset",
              message: "Passowrd reset initiation sucessful. A mail was sent to email address to reset your password",
            },
          });
        },
        () => {
          setError("Cannot login with credentials");
        }
      )
    );
  };
  const { errors, register, handleSubmit } = useForm();
  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-0 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard
            //  className="card-bordered"
            bodyClass="card-inner-lg"
          >
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Forgot Password</BlockTitle>
                <BlockDes>
                  <p>Input your email to initiate forgot password</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> Unable to login with credentials{" "}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="email"
                    id="default-01"
                    name="email"
                    ref={register({ required: "This field is required" })}
                    value={payload.email}
                    onChange={(e) => {
                      setPayload({ ...payload, email: e.target.value });
                    }}
                    placeholder="Enter your email address"
                    className="form-control-lg form-control"
                  />
                  {errors.email && <span className="invalid">{errors.email.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <Button size="lg" className="btn-block" type="submit" color="primary">
                  {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
                </Button>
              </FormGroup>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              Go back to{" "}
              <Link to={`/login`}>
                <strong>login</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
