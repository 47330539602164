import { CARRIER_FAILED, CARRIER_LOADED, CARRIER_LOADING } from "./types";

const initialState = {
  loading: false,
  carriers: [],
};

const carrier = (state = initialState, action) => {
  switch (action.type) {
    case CARRIER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CARRIER_LOADED:
      return {
        ...state,
        loadinng: false,
        carriers: action.payload.model,
      };
    case CARRIER_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default carrier;
