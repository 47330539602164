import { allClientsUrl, addClientsUrl } from "./apis";
import { CLIENT_ADDED, CLIENT_FAILED, CLIENT_LOADED, CLIENT_LOADING, CLIENT_SELECT } from "./types";
import Axios from "axios";
import { tokenConfig } from "../actions";

export const loadClients = () => (dispatch, getState) => {
  dispatch({ type: CLIENT_LOADING });
  Axios.get(allClientsUrl, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: CLIENT_LOADED, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: CLIENT_FAILED, payload: err.response });
    });
};

export const addClient = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: CLIENT_LOADING });
  Axios.post(addClientsUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: CLIENT_ADDED, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: CLIENT_FAILED, payload: err.response });
      errFunc();
    });
};
export const selectClient = (body) => (dispatch) => {
  dispatch({ type: CLIENT_SELECT, payload: body });
};
