import React, { useState, useEffect } from "react";
import Logo from "../../images/onwl.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "../../pages/auth/AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { FormGroup, Spinner } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Actions from "../../redux/actions";

const AccountVerify = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const [msg, setMsg] = useState("");

  const verifyUser = () => {
    dispatch(
      Actions.verifyUser(
        { token: query.get("token") },
        (res) => {
          setMsg(res.msg);
        },
        (err) => {
          setMsg(err.data.msg);
        }
      )
    );
  };
  useEffect(() => {
    verifyUser();
  }, []);
  return (
    <React.Fragment>
      <Head title="Account Verification" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-0 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard bodyClass="card-inner-lg">
            {loading ? (
              <BlockHead>
                <BlockContent>
                  <BlockDes>
                    <p>Loading</p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
            ) : (
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4">Account Verifcation</BlockTitle>
                  <BlockDes>
                    <p>{msg}</p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
            )}

            <FormGroup>
              <Button
                size="lg"
                className="btn-block"
                type="button"
                color="primary"
                onClick={() => {
                  history.push("/login");
                }}
              >
                {loading ? <Spinner size="sm" color="light" /> : "Proceed to login"}
              </Button>
            </FormGroup>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default AccountVerify;
