import { combineReducers } from "redux";
import auth from "./Auth/auth";
import user from "./User/user";
import order from "./Order/order";
import coverage from "./Coverage/coverage";
import client from "./Client/client";
import carrier from "./Carrier/carrier";
import business from "./Business/business";
import wallet from "./Wallet/wallet";
import dispatcher from "./Dispatcher/dispatcher";

const reducers = combineReducers({
  auth,
  user,
  client,
  order,
  carrier,
  business,
  coverage,
  dispatcher,
  wallet,
});

export default reducers;
