import {
  COVERAGE_FAILED,
  COVERAGE_LOADED,
  COVERAGE_LOADING,
  COVERAGE_COMPANY_PACK,
  COVERAGE_COMPANY_ADDED,
  COVERAGE_COMPANY_LOADED,
  COVERAGE_COMPANY_SELECT,
  COVERAGE_COMPANY_DISTRO,
  COVERAGE_COMPANY_PACK_ADDED,
  COVERAGE_COMPANY_DISTRO_ADDED,
} from "./types";

const initialState = {
  loading: false,
  coverages: [],
  comCoverages: [],
  comCoverage: {},
  coverageState: "",
  sizes: [],
  pricing: [],
};

const coverage = (state = initialState, action) => {
  switch (action.type) {
    case COVERAGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case COVERAGE_LOADED:
      return {
        ...state,
        loading: false,
        coverages: action.payload.model,
        coverageState: action.state,
      };
    case COVERAGE_COMPANY_LOADED:
      return {
        ...state,
        loading: false,
        comCoverages: action.payload.model,
      };
    case COVERAGE_COMPANY_SELECT:
      return {
        ...state,
        loading: false,
        comCoverage: action.payload,
      };
    case COVERAGE_COMPANY_ADDED:
      return {
        ...state,
        loading: false,
        comCoverages: [...state.comCoverages, action.payload.model],
      };
    case COVERAGE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case COVERAGE_COMPANY_DISTRO_ADDED:
      return {
        ...state,
        loading: false,
        sizes: [...state.sizes, action.payload.model],
      };
    case COVERAGE_COMPANY_PACK:
      return {
        ...state,
        loading: false,
        pricing: action.payload.model,
      };
    case COVERAGE_COMPANY_PACK_ADDED:
      return {
        ...state,
        loading: false,
        pricing: [...state.pricing, ...action.payload.model],
      };
    case COVERAGE_COMPANY_DISTRO:
      return {
        ...state,
        loading: false,
        sizes: action.payload.model,
      };
    default:
      return state;
  }
};
export default coverage;
