import {
  BUSINESS_ADDED,
  BUSINESS_FAILED,
  BUSINESS_LOADED,
  BUSINESS_LOADING,
  BUSINESS_SET,
  BUSINESS_UPDATE,
  BUSINESS_PAYLOAD,
} from "./types";

const initialState = {
  loading: false,
  businesses: [],
  business: null,
  busPayload: { title: "", email: "", address: "", lat: 0, lon: 0, state: "", country: "" },
};

const business = (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case BUSINESS_LOADED:
      return {
        ...state,
        loading: false,
        businesses: action.payload.model,
      };
    case BUSINESS_ADDED:
      return {
        ...state,
        loading: false,
        businesses: [...state.businesses, action.payload.model],
      };
    case BUSINESS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case BUSINESS_UPDATE:
      return {
        ...state,
        loading: false,
        business: action.payload.model,
      };
    case BUSINESS_SET:
      return {
        ...state,
        loading: false,
        business: action.payload,
      };
    case BUSINESS_PAYLOAD:
      return {
        ...state,
        busPayload: action.payload,
      };
    default:
      return state;
  }
};
export default business;
