import { addUserUrl, loadUsersUrl, updateUser2Url, loadInviteUrl } from "./apis";
import {
  USER_ADDED,
  USER_FAILED,
  USER_LOADED,
  USER_LOADING,
  USER_SELECT,
  USER_UPDATE,
  USER_INVITE,
  USER_INVITE_DELETE,
  USER_INVITE_SELECT,
} from "./types";
import Axios from "axios";
import { tokenConfig } from "../actions";

export const loadUsers = () => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });
  Axios.get(loadUsersUrl, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: USER_LOADED, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: USER_FAILED, payload: err.response });
    });
};
export const loadUserInvite = () => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });
  Axios.get(loadInviteUrl, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: USER_INVITE, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: USER_FAILED, payload: err.response });
    });
};
export const deleteUserInvite = (id, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });
  Axios.delete(`${loadInviteUrl}${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch(loadUserInvite());
      func();
      dispatch({ type: USER_INVITE_DELETE, payload: res.data });
    })
    .catch((err) => {
      errFunc();
      dispatch({ type: USER_FAILED, payload: err.response });
    });
};
export const setUserInvite = (body) => (dispatch) => {
  dispatch({ type: USER_INVITE_SELECT, payload: body });
};
export const addUser = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });
  Axios.post(addUserUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: USER_ADDED, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: USER_FAILED, payload: err.response });
      errFunc();
    });
};
export const updateUser2 = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });
  Axios.put(updateUser2Url, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: USER_UPDATE, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: USER_FAILED, payload: err.response });
      errFunc();
    });
};

export const selectUser = (body) => (dispatch, getState) => {
  dispatch({ type: USER_SELECT, payload: body });
};
