export const COVERAGE_FAILED = "COVERAGE_FAILED";
export const COVERAGE_LOADED = "COVERAGE_LOADED";
export const COVERAGE_LOADING = "COVERAGE_LOADING";
export const COVERAGE_COMPANY_PACK = "COVERAGE_COMPANY_PACK";
export const COVERAGE_COMPANY_ADDED = "COVERAGE_COMPANY_ADDED";
export const COVERAGE_COMPANY_LOADED = "COVERAGE_COMPANY_LOADED";
export const COVERAGE_COMPANY_SELECT = "COVERAGE_COMPANY_SELECT";
export const COVERAGE_COMPANY_DISTRO = "COVERAGE_COMPANY_DISTRO";
export const COVERAGE_COMPANY_PACK_ADDED = "COVERAGE_COMPANY_PACK_ADDED";
export const COVERAGE_COMPANY_DISTRO_ADDED = "COVERAGE_COMPANY_DISTRO_ADDED";
