import { CLIENT_ADDED, CLIENT_FAILED, CLIENT_LOADED, CLIENT_LOADING, CLIENT_SELECT } from "./types";

const initialState = {
  loading: false,
  clients: [],
  client: {},
};

const client = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_LOADING:
      return {
        ...state,
        loading: false,
      };
    case CLIENT_LOADED:
      return {
        ...state,
        loading: false,
        clients: action.payload.model,
      };
    case CLIENT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case CLIENT_ADDED:
      return {
        ...state,
        loading: false,
        clients: [...state.clients, action.payload.model],
      };
    case CLIENT_SELECT:
      return {
        ...state,
        client: action.payload,
      };
    default:
      return state;
  }
};
export default client;
