import {
  AUTH_FAILED,
  AUTH_LOADED,
  AUTH_LOADING,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_REGISTER,
  AUTH_VERIFY,
  AUTH_FORGOT,
  AUTHH_RESET,
  AUTH_PASS_UPDATE,
  AUTH_UPDATE,
  AUTH_KEY_GENERATE,
} from "./types";

const initialState = {
  isAuth: false,
  loading: false,
  user: null,
  token: localStorage.getItem("OnwlClientToken"),
  perms: null,
  business: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AUTH_LOADED:
      return {
        ...state,
        loading: false,
        user: action.payload.model,
        perms: action.payload.perms,
        isAuth: true,
        business: action.payload.business,
      };
    case AUTH_KEY_GENERATE:
      return {
        ...state,
        loading: false,
        user: state.user.id ? { ...state.user, token: action.payload.token } : { token: action.payload.token },
      };
    case AUTH_LOGIN:
      localStorage.setItem("OnwlClientToken", action.payload.token);
      return {
        ...state,
        loading: false,
        user: action.payload.model,
        isAuth: true,
        perms: action.payload.perms,
        token: action.payload.token,
        business: action.payload.business,
      };
    case AUTH_REGISTER:
    case AUTH_VERIFY:
    case AUTH_FORGOT:
    case AUTHH_RESET:
    case AUTH_PASS_UPDATE:
      return {
        ...state,
        loading: false,
      };
    case AUTH_UPDATE:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case AUTH_FAILED:
      return {
        ...initialState,
        loading: false,
      };
    case AUTH_LOGOUT:
      localStorage.removeItem("OnwlClientToken");
      return {
        ...state,
        isAuth: false,
        loading: false,
        user: null,
        token: null,
        perms: null,
      };
    default:
      return state;
  }
};
export default auth;
