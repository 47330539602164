import {
  USER_ADDED,
  USER_FAILED,
  USER_LOADED,
  USER_LOADING,
  USER_SELECT,
  USER_INVITE,
  USER_INVITE_DELETE,
  USER_UPDATE,
  USER_INVITE_SELECT,
} from "./types";

const initialState = {
  loading: false,
  users: [],
  user: {},
  userInvites: [],
  userInvite: {},
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        loading: false,
        users: action.payload.model,
      };
    case USER_ADDED:
      return {
        ...state,
        loading: false,
        users: [...state.users, action.payload.model],
      };
    case USER_INVITE:
      return {
        ...state,
        loading: false,
        userInvites: action.payload.model,
      };
    case USER_INVITE_SELECT:
      return {
        ...state,
        userInvite: action.payload,
      };
    case USER_INVITE_DELETE:
      return {
        ...state,
        loading: false,
        userInvite: {},
      };
    case USER_SELECT:
      return {
        ...state,
        user: action.payload,
      };
    case USER_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default user;
