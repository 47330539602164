import {
  ORDER_FAILED,
  ORDER_LOADED,
  ORDER_LOADING,
  ORDER_SELECT,
  ORDER_STEP,
  ORDER_DATA,
  ORDER_DATA_RESET,
  ORDER_INIT,
  ORDER_CLIENT,
  ORDER_TRANS_INIT,
  ORDER_VERIFY,
} from "./types";
import { orderInitUrl, orderClientUrl, orderTransInitUrl, getOrdersUrl, verifyOrderUrl } from "./apis";
import Axios from "axios";
import { tokenConfig } from "../actions";

export const getOrder = () => (dispatch, getState) => {
  dispatch({ type: ORDER_LOADING });
  Axios.get(getOrdersUrl, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ORDER_LOADED, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: ORDER_FAILED, payload: err.response });
    });
};

export const initOrder = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: ORDER_LOADING });
  Axios.post(orderInitUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: ORDER_INIT, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: ORDER_FAILED, payload: err.response });
      errFunc();
    });
};
export const verifyOrder = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: ORDER_LOADING });
  Axios.post(verifyOrderUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: ORDER_VERIFY, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: ORDER_FAILED, payload: err.response });
      errFunc();
    });
};
export const initPay = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: ORDER_LOADING });
  Axios.post(orderTransInitUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: ORDER_TRANS_INIT, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ORDER_FAILED, payload: err.response });
      errFunc();
    });
};
export const clientOrder = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: ORDER_LOADING });
  Axios.post(orderClientUrl, body, tokenConfig(getState))
    .then((res) => {
      func(res.data);
      dispatch({ type: ORDER_CLIENT, payload: res.data });
    })
    .catch((err) => {
      errFunc();
      dispatch({ type: ORDER_FAILED, payload: err.response });
    });
};
export const setOrderStep = (step, data) => (dispatch) => {
  dispatch({ type: ORDER_STEP, payload: { step, data } });
};

export const setOrder = (payload) => (dispatch) => {
  dispatch({ type: ORDER_SELECT, payload });
};

export const setOrderData = (key, payload) => (dispatch) => {
  dispatch({ type: ORDER_DATA, payload, key });
};
export const resetOrderData = () => (dispatch) => {
  dispatch({ type: ORDER_DATA_RESET });
};

export const getAddressData = (body, lat, lon, address) => {
  const data = {
    state: "",
    country: "",
    address,
    lat,
    lon,
  };
  const d1 = sliptInLocation(body);
  data.state = d1?.state;
  data.country = d1.country;

  return data;
};
const sliptInLocation = (body) => {
  let strPriority = {
    5: "administrative_area_level_1",
    6: "country",
  };

  let addrRepr = {
    5: "state",
    6: "country",
  };

  let resultAddr = {
    state: "",
    country: "",
  };
  body.forEach((r) => {
    let currKey = null;
    if (
      Object.keys(strPriority).some((a) => {
        currKey = a;
        return r.types.includes(strPriority[a]);
      })
    ) {
      resultAddr[addrRepr[currKey]] = r.long_name;
    }
  });
  return resultAddr;
};
