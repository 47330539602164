//export const baseUrl = ;
//export const baseUrl = process.env.NODE_ENV === "development" ? "http://localhost:8080/zp/api/v1" : "/zp/api/v1";
export const baseUrl = "https://api.goonwl.com/zp/api/v1";

export const loginUrl = `${baseUrl}/client/login`;
export const loadUserUrl = `${baseUrl}/client`;
export const registerUserUrl = `${baseUrl}/client/register`;
export const verifyUserUrl = `${baseUrl}/client/verify`;
export const forgotUserUrl = `${baseUrl}/client/forgotpassword`;
export const resetUserUrl = `${baseUrl}/client/resetpassword`;
export const updateUserUrl = `${baseUrl}/client/update`;
export const updateUserPassUrl = `${baseUrl}/client/changepassword`;
export const generateAuthKeyUrl = `${baseUrl}/client/auth_key`;
