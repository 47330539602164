import { addDispatcherUrl, loadDispatchersUrl, updateDispatcherUrl, dispatcherCarrierUrl } from "./apis";
import {
  DISPATCHER_ADDED,
  DISPATCHER_FAILED,
  DISPATCHER_LOADED,
  DISPATCHER_LOADING,
  DISPATCHER_SELECT,
  DISPATCHER_UPDATED,
  DISPATCHER_CARRIER,
} from "./types";
import Axios from "axios";
import { tokenConfig } from "../actions";

export const loadDispatchers = (company) => (dispatch, getState) => {
  dispatch({ type: DISPATCHER_LOADING });
  Axios.get(loadDispatchersUrl + company, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DISPATCHER_LOADED, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: DISPATCHER_FAILED, payload: err.response });
    });
};
export const loadDispatcherCarrier = (id,func) => (dispatch, getState) => {
  dispatch({ type: DISPATCHER_LOADING });
  Axios.get(dispatcherCarrierUrl + id, tokenConfig(getState))
      .then((res) => {
        func()
      dispatch({ type: DISPATCHER_CARRIER, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: DISPATCHER_FAILED, payload: err.response });
    });
};
export const addDispatcher = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: DISPATCHER_LOADING });
  Axios.post(addDispatcherUrl, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DISPATCHER_ADDED, payload: res.data });
      func();
    })
    .catch((err) => {
      dispatch({ type: DISPATCHER_FAILED, payload: err.response });
      errFunc();
    });
};

export const updateDispatcher = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: DISPATCHER_LOADING });
  Axios.put(updateDispatcherUrl, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DISPATCHER_UPDATED, payload: res.data });
      func();
    })
    .catch((err) => {
      dispatch({ type: DISPATCHER_FAILED, payload: err.response });
      errFunc();
    });
};
export const setDispatcher = (body) => (dispatch) => {
  dispatch({ type: DISPATCHER_SELECT, payload: body });
};
