import {
  ORDER_FAILED,
  ORDER_LOADED,
  ORDER_LOADING,
  ORDER_SELECT,
  ORDER_STEP,
  ORDER_DATA,
  ORDER_DATA_RESET,
  ORDER_INIT,
  ORDER_TRANS_INIT,
  ORDER_CLIENT,
  ORDER_VERIFY,
} from "./types";

const initialState = {
  loading: false,
  orderStep: 0,
  orderData: {
    itemSize: 8.0,
    orderItems: [
      {
        itemName: "",
        price: 0,
        unit: null,
      },
    ],
  },
  order: null,
  orderInit: null,
  orderClient: null,
  orderClientSearch: false,
  orderPricing: {},
  initPay: {},
  orders: [],
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ORDER_LOADED:
      return {
        ...state,
        loading: false,
        orders: action.payload.model,
        reload: false,
      };
    case ORDER_STEP:
      return {
        ...state,
        orderStep: action.payload.step,
        // orderData: { ...state.orderData, ...action.payload.data },
      };
    case ORDER_SELECT:
      return {
        ...state,
        order: action.payload,
      };
    case ORDER_FAILED:
    case ORDER_VERIFY:
      return {
        ...state,
        loading: false,
      };
    case ORDER_DATA:
      return {
        ...state,
        orderData: { ...state.orderData, [action.key]: action.payload },
      };
    case ORDER_INIT:
      return {
        ...state,
        loading: false,
        orderInit: action.payload.model,
        orderPricing: action.payload.pricing,
      };
    case ORDER_TRANS_INIT:
      return {
        ...state,
        loading: false,
        initPay: action.payload.model,
      };
    case ORDER_DATA_RESET:
      return {
        ...state,
        orderStep: 0,
        orderData: { itemSize: 0.4 },
        order: null,
        orderInit: null,
        orderClient: null,
        orderClientSearch: false,
      };
    case ORDER_CLIENT:
      return {
        ...state,
        orderClient: action.payload.model,
        orderClientSearch: true,
      };

    default:
      return state;
  }
};
export default order;
