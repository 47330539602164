import {
  loadCoverageUrl,
  loadCompanyCoverageUrl,
  addCompanyCoverageUrl,
  loadCompanyPackageDistoUrl,
  loadComCovPackoUrl,
} from "./apis";
import {
  COVERAGE_COMPANY_ADDED,
  COVERAGE_COMPANY_LOADED,
  COVERAGE_COMPANY_PACK,
  COVERAGE_COMPANY_PACK_ADDED,
  COVERAGE_FAILED,
  COVERAGE_LOADED,
  COVERAGE_LOADING,
  COVERAGE_COMPANY_SELECT,
  COVERAGE_COMPANY_DISTRO,
  COVERAGE_COMPANY_DISTRO_ADDED,
} from "./types";
import Axios from "axios";
import { tokenConfig } from "../actions";

export const loadCoverage = (state) => (dispatch, getState) => {
  dispatch({ type: COVERAGE_LOADING });
  Axios.get(loadCoverageUrl + state, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: COVERAGE_LOADED, payload: res.data, state });
    })
    .catch((err) => {
      dispatch({ type: COVERAGE_FAILED, payload: err.response });
    });
};
export const loadCompanyDistro = (business) => (dispatch, getState) => {
  dispatch({ type: COVERAGE_LOADING });
  Axios.get(loadCompanyPackageDistoUrl + business, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: COVERAGE_COMPANY_DISTRO, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: COVERAGE_FAILED, payload: err.response });
    });
};
export const loadComCovPack = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: COVERAGE_LOADING });
  Axios.post(loadComCovPackoUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: COVERAGE_COMPANY_PACK, payload: res.data });
    })
    .catch((err) => {
      errFunc();
      dispatch({ type: COVERAGE_FAILED, payload: err.response });
    });
};
export const addComCovPack = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: COVERAGE_LOADING });
  Axios.post(loadComCovPackoUrl + "add", body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: COVERAGE_COMPANY_PACK_ADDED, payload: res.data });
    })
    .catch((err) => {
      errFunc();
      dispatch({ type: COVERAGE_FAILED, payload: err.response });
    });
};
export const loadComapanyCoverage = (company) => (dispatch, getState) => {
  dispatch({ type: COVERAGE_LOADING });
  Axios.get(loadCompanyCoverageUrl + company, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: COVERAGE_COMPANY_LOADED, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: COVERAGE_FAILED, payload: err.response });
    });
};
export const addComapanyCoverage = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: COVERAGE_LOADING });
  Axios.post(addCompanyCoverageUrl, body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: COVERAGE_COMPANY_ADDED, payload: res.data });
    })
    .catch((err) => {
      errFunc(err);
      dispatch({ type: COVERAGE_FAILED, payload: err.response });
    });
};
export const addComapanyDistro = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: COVERAGE_LOADING });
  Axios.post(loadCompanyPackageDistoUrl + "add", body, tokenConfig(getState))
    .then((res) => {
      func();
      dispatch({ type: COVERAGE_COMPANY_DISTRO_ADDED, payload: res.data });
    })
    .catch((err) => {
      errFunc();
      dispatch({ type: COVERAGE_FAILED, payload: err.response });
    });
};
export const selectCompanyCoverage = (body) => (dispatch) => {
  dispatch({ type: COVERAGE_COMPANY_SELECT, payload: body });
};
