import { walletIntentUrl, walletVerifyUrl, walletHistoryUrl } from "./apis";
import { tokenConfig } from "../actions";
import { WALLET_INTENT, WALLET_LOADING, WALLET_VERIFY, WALLET_FAILED, WALLET_HISTORY, WALLET_SELECT } from "./types";
import Axios from "axios";

export const walletIntent = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: WALLET_LOADING });
  Axios.post(walletIntentUrl, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: WALLET_INTENT, payload: res.data });
      func(res.data);
    })
    .catch((err) => {
      dispatch({ type: WALLET_FAILED, payload: err.response });
      errFunc();
      console.log(err);
    });
};

export const walletVerify = (body, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: WALLET_LOADING });
  Axios.post(walletVerifyUrl, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: WALLET_VERIFY, payload: res.data });
      func();
    })
    .catch((err) => {
      dispatch({ type: WALLET_FAILED, payload: err.response });
      errFunc();
    });
};

export const walletHistory = (user, func, errFunc) => (dispatch, getState) => {
  dispatch({ type: WALLET_LOADING });
  Axios.get(walletHistoryUrl + user, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: WALLET_HISTORY, payload: res.data });
      func();
    })
    .catch((err) => {
      dispatch({ type: WALLET_FAILED, payload: err.response });
      errFunc();
    });
};
export const setWallet = (body) => (dispatch) => {
  dispatch({ type: WALLET_SELECT, payload: body });
};
