export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_LOADED = "AUTH_LOADED";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REGISTER = "AUTH_REGISTER";
export const AUTH_VERIFY = "AUTH_VERIFY";
export const AUTH_FORGOT = "AUTH_FORGOT";
export const AUTHH_RESET = "AUTHH_RESET";
export const AUTH_UPDATE = "AUTH_UPDATE";
export const AUTH_PASS_UPDATE = "AUTH_PASS_UPDATE";
export const AUTH_KEY_GENERATE = "AUTH_KEY_GENERATE";
