import {
  DISPATCHER_ADDED,
  DISPATCHER_FAILED,
  DISPATCHER_LOADED,
  DISPATCHER_LOADING,
  DISPATCHER_SELECT,
  DISPATCHER_UPDATED,
  DISPATCHER_CARRIER,
} from "./types";

const initialState = {
  loading: false,
  dispatchers: [],
  dispatcher: {},
  carrier: {},
};
const dispatcher = (state = initialState, action) => {
  switch (action.type) {
    case DISPATCHER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DISPATCHER_SELECT:
      return {
        ...state,
        loading: false,
        dispatcher: action.payload,
      };
    case DISPATCHER_ADDED:
      return {
        ...state,
        loading: false,
        dispatchers: [...state.dispatchers, action.payload.model],
      };
    case DISPATCHER_LOADED:
      return {
        ...state,
        loading: false,
        dispatchers: action.payload.model,
      };
    case DISPATCHER_FAILED:
      return {
        ...state,
        loading: false,
      };
    case DISPATCHER_CARRIER:
      return {
        ...state,
        loading: false,
        carrier: action.payload.model,
      };
    case DISPATCHER_UPDATED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default dispatcher;
