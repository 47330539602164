import React, { useState, useEffect } from "react";
import Logo from "../../images/onwl.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "../../pages/auth/AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as Actions from "../../redux/actions";
import { useHistory } from "react-router-dom";
const Register = () => {
  const loading = useSelector((state) => state.auth.loading);

  const dispatch = useDispatch();
  const history = useHistory();
  const [payload, setPayload] = useState({ email: "", password: "", firstName: "", lastName: "", phone: "" });
  const [errorVal, setError] = useState("");
  const [passState, setPassState] = useState(false);

  const onFormSubmit = (formData) => {
    dispatch(
      Actions.registerUser(
        formData,
        () => {
          history.push("/response", {
            data: {
              title: "Congratulations",
              message:
                "You have successfully created your account. A mail has been sent to your email address to verify your account. Please verify your account to proceed",
            },
          });
        },
        (err) => {
          console.log(err);
          setError(err.data ? err.data.message.message : "Cannot login with credentials");
        }
      )
    );
  };

  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Head title="Signup" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-0 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard
            //  className="card-bordered"
            bodyClass="card-inner-lg"
          >
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Sign-In</BlockTitle>
                <BlockDes>
                  <p>Input your info to create a new account</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}{" "}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    ref={register({ required: "This field is required" })}
                    value={payload.email}
                    onChange={(e) => {
                      setPayload({ ...payload, email: e.target.value });
                    }}
                    placeholder="Enter your email address"
                    className="form-control-lg form-control"
                  />
                  {errors.email && <span className="invalid">{errors.email.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    First Name
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    ref={register({ required: "This field is required" })}
                    value={payload.firstName}
                    onChange={(e) => {
                      setPayload({ ...payload, firstName: e.target.value });
                    }}
                    placeholder="Enter your first name "
                    className="form-control-lg form-control"
                  />
                  {errors.firstName && <span className="invalid">{errors.firstName.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Last Name
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    ref={register({ required: "This field is required" })}
                    value={payload.lastName}
                    onChange={(e) => {
                      setPayload({ ...payload, lastName: e.target.value });
                    }}
                    placeholder="Enter your last name "
                    className="form-control-lg form-control"
                  />
                  {errors.lastName && <span className="invalid">{errors.lastName.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Phone
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    ref={register({ required: "This field is required" })}
                    value={payload.phone}
                    onChange={(e) => {
                      setPayload({ ...payload, phone: e.target.value });
                    }}
                    placeholder="Enter your phone number "
                    className="form-control-lg form-control"
                  />
                  {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Passcode
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="password"
                    ref={register({ required: "This field is required" })}
                    value={payload.password}
                    onChange={(e) => {
                      setPayload({ ...payload, password: e.target.value });
                    }}
                    placeholder="Enter your new password"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <Button size="lg" className="btn-block" type="submit" color="primary">
                  {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
                </Button>
              </FormGroup>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              Already have an account?{" "}
              <Link to={`/login`}>
                <strong>Sign in instead</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Register;
