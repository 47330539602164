import React, { useEffect, lazy, Suspense, useState } from "react";
import Layout from "./layout/Index";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import LoginPage from "./Auth/Login";
import RegisterPage from "./Auth/Register";
import { useDispatch } from "react-redux";
import * as Actions from "./redux/actions";
import { useSelector } from "react-redux";
const Error404Modern = lazy(() => import("./pages/error/404-modern"));
import PublicRoute from "./route/PublicRoute";
import PrivateRoute from "./route/PrivateRoute";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Congratulations from "./Auth/Congratulations";
import AccountVerify from "./Auth/AccountVerify";
import ForgotPassword from "./Auth/ForgotPassword";
import ResetPassword from "./Auth/ResetPassword";

const App = () => {
  const [loadMap, setLoadMap] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const loading = useSelector((state) => state.auth.loading);
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const loadGoogleMapScript = (callback) => {
    if (typeof window.google === "object" && typeof window.google.maps === "object") {
      callback();
    } else {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", callback);
    }
  };

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
    dispatch(Actions.loadUser());
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(Actions.getOrder());
      dispatch(Actions.loadBusiness());
      dispatch(
        Actions.walletHistory(
          user.id,
          () => {},
          () => {}
        )
      );
    }
  }, [user]);

  return (
    // <h1>`helklo world</h1>
    <>
      <ToastContainer />

      <Router basename="/">
        {/* <Suspense fallback={<div />}> */}
        <Switch>
          <Redirect exact from="/" to="/login" />
          <PublicRoute path="/login" isAuth={isAuth} loading={loading} token={token}>
            <LoginPage />
          </PublicRoute>
          <PublicRoute path="/signup" isAuth={isAuth} loading={loading} token={token}>
            <RegisterPage />
          </PublicRoute>
          <PublicRoute path="/response" isAuth={isAuth} loading={loading} token={token}>
            <Congratulations />
          </PublicRoute>
          <PublicRoute path="/verify" isAuth={isAuth} loading={loading} token={token}>
            <AccountVerify />
          </PublicRoute>
          <PublicRoute path="/forgotpassword" isAuth={isAuth} loading={loading} token={token}>
            <ForgotPassword />
          </PublicRoute>
          <PublicRoute path="/resetpassword" isAuth={isAuth} loading={loading} token={token}>
            <ResetPassword />
          </PublicRoute>
          <PrivateRoute path="/" isAuth={isAuth} loading={loading} token={token}>
            <Layout />
          </PrivateRoute>
          <Route path="*">
            <Error404Modern />
          </Route>
        </Switch>
        {/* </Suspense> */}
      </Router>
    </>
  );
};
export default App;
