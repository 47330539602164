import { lazy } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
const Error404Modern = lazy(() => import("../pages/error/404-modern"));

function PrivateRoute({ children, isAuth, token, loading, ...rest }) {
  return (
    <Route
      {...rest}
      exact={true}
      render={({ location }) =>
        location.state && location.state.is404 ? (
          <Error404Modern />
        ) : isAuth ? (
          children
        ) : token ? (
          <div>loading</div>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
